var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "46",
      "height": "47",
      "viewBox": "0 0 46 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M21.082 6.2487C21.082 5.19015 21.9402 4.33203 22.9987 4.33203C24.0572 4.33203 24.9154 5.19015 24.9154 6.2487V8.16536H34.4987C38.7329 8.16536 42.1654 11.5978 42.1654 15.832V31.1654C42.1654 35.3996 38.7329 38.832 34.4987 38.832H33.7667L34.2964 39.8915C34.7698 40.8383 34.386 41.9896 33.4392 42.463C32.4924 42.9364 31.3412 42.5526 30.8678 41.6058L29.4809 38.832H16.5165L15.1296 41.6058C14.6562 42.5526 13.5049 42.9364 12.5582 42.463C11.6114 41.9896 11.2276 40.8383 11.701 39.8915L12.2307 38.832H11.4987C7.26451 38.832 3.83203 35.3996 3.83203 31.1654V15.832C3.83203 11.5978 7.26452 8.16536 11.4987 8.16536H21.082V6.2487ZM15.3758 34.9987C15.3475 34.9981 15.3193 34.9981 15.2912 34.9987H11.4987C9.38161 34.9987 7.66536 33.2825 7.66536 31.1654V15.832C7.66536 13.7149 9.38161 11.9987 11.4987 11.9987H34.4987C36.6158 11.9987 38.332 13.7149 38.332 15.832V31.1654C38.332 33.2825 36.6158 34.9987 34.4987 34.9987H30.7062C30.6781 34.9981 30.6499 34.9981 30.6216 34.9987H15.3758Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M33.7807 16.2521C34.6073 16.9134 34.7413 18.1195 34.08 18.9461L26.4133 28.5294C25.7923 29.3058 24.6807 29.4783 23.8535 28.9269L19.4109 25.9651L14.772 30.6041C14.0234 31.3526 12.8099 31.3526 12.0614 30.6041C11.3129 29.8556 11.3129 28.642 12.0614 27.8935L17.8114 22.1435C18.4574 21.4974 19.4696 21.3972 20.2298 21.904L24.5177 24.7626L31.0867 16.5514C31.7479 15.7249 32.9541 15.5908 33.7807 16.2521Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }