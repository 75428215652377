var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "36",
      "height": "37",
      "viewBox": "0 0 36 37",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "35.7433",
      "height": "36.6484",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M26.2114 11.9102L14.7438 23.6682L9.53125 18.3236",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }