var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "46",
      "height": "47",
      "viewBox": "0 0 46 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M38.3333 12.0013H7.66667C5.54958 12.0013 3.83333 13.7175 3.83333 15.8346V31.168C3.83333 33.2851 5.54958 35.0013 7.66667 35.0013H38.3333C40.4504 35.0013 42.1667 33.2851 42.1667 31.168V15.8346C42.1667 13.7175 40.4504 12.0013 38.3333 12.0013ZM7.66667 8.16797C3.43248 8.16797 0 11.6005 0 15.8346V31.168C0 35.4021 3.43248 38.8346 7.66667 38.8346H38.3333C42.5675 38.8346 46 35.4021 46 31.168V15.8346C46 11.6005 42.5675 8.16797 38.3333 8.16797H7.66667Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M44.0846 19.6654C44.0846 20.7239 43.2265 21.582 42.168 21.582C36.8752 21.582 32.5846 17.2914 32.5846 11.9987C32.5846 10.9402 33.4428 10.082 34.5013 10.082C35.5598 10.082 36.418 10.9402 36.418 11.9987C36.418 15.1743 38.9923 17.7487 42.168 17.7487C43.2265 17.7487 44.0846 18.6068 44.0846 19.6654Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.83464 25.4154C2.77609 25.4154 1.91797 26.2735 1.91797 27.332C1.91797 28.3906 2.77609 29.2487 3.83464 29.2487C7.01027 29.2487 9.58463 31.8231 9.58463 34.9987C9.58463 36.0572 10.4428 36.9154 11.5013 36.9154C12.5598 36.9154 13.418 36.0572 13.418 34.9987C13.418 29.706 9.12736 25.4154 3.83464 25.4154Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M42.168 25.4154C43.2265 25.4154 44.0846 26.2735 44.0846 27.332C44.0846 28.3906 43.2265 29.2487 42.168 29.2487C38.9923 29.2487 36.418 31.8231 36.418 34.9987C36.418 36.0572 35.5598 36.9154 34.5013 36.9154C33.4428 36.9154 32.5846 36.0572 32.5846 34.9987C32.5846 29.706 36.8752 25.4154 42.168 25.4154Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M3.83464 21.582C2.77609 21.582 1.91797 20.7239 1.91797 19.6654C1.91797 18.6068 2.77609 17.7487 3.83464 17.7487C7.01027 17.7487 9.58463 15.1743 9.58463 11.9987C9.58463 10.9402 10.4428 10.082 11.5013 10.082C12.5598 10.082 13.418 10.9402 13.418 11.9987C13.418 17.2914 9.12736 21.582 3.83464 21.582Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M22.9987 27.332C25.1158 27.332 26.832 25.6158 26.832 23.4987C26.832 21.3816 25.1158 19.6654 22.9987 19.6654C20.8816 19.6654 19.1654 21.3816 19.1654 23.4987C19.1654 25.6158 20.8816 27.332 22.9987 27.332ZM22.9987 31.1654C27.2329 31.1654 30.6654 27.7329 30.6654 23.4987C30.6654 19.2645 27.2329 15.832 22.9987 15.832C18.7645 15.832 15.332 19.2645 15.332 23.4987C15.332 27.7329 18.7645 31.1654 22.9987 31.1654Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }