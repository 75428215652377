var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Hero'), _c('MarketTrends', {
    attrs: {
      "marketTypeFromHome": _vm.marketType,
      "showFullFromHome": _vm.showFull
    },
    on: {
      "setMetaData": _vm.setMetaData
    }
  }), _c('WhyNozax'), _c('AreYouReady', {
    staticClass: "dd"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }