var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "547",
      "height": "422",
      "viewBox": "0 0 547 422",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M175 125H92C92 114.146 99.0543 104.915 108.877 101.538C111.332 90.3672 121.405 82 133.46 82C145.497 82 155.56 90.3496 158.025 101.503C167.901 104.853 175 114.111 175 125Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M65.1232 105L39.5156 130.804L30.0489 121.265L2 149.528",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M48.8711 105H65.4686V121.725",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M20 28.1875L32.3669 36.2892",
      "stroke": "#07B53B",
      "stroke-width": "4.17428",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M22.1641 38.4665L30.2011 26",
      "stroke": "#07B53B",
      "stroke-width": "4.17428",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M294.798 141.653C294.798 145.121 292.011 147.914 288.595 147.914C285.179 147.914 282.391 145.121 282.391 141.653C282.391 138.185 285.179 135.391 288.595 135.391C292.011 135.391 294.798 138.185 294.798 141.653Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2.78286"
    }
  }), _c('path', {
    attrs: {
      "d": "M170.798 9.65285C170.798 13.121 168.011 15.9143 164.595 15.9143C161.179 15.9143 158.391 13.121 158.391 9.65285C158.391 6.18473 161.179 3.39143 164.595 3.39143C168.011 3.39143 170.798 6.18473 170.798 9.65285Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2.78286"
    }
  }), _c('path', {
    attrs: {
      "d": "M96.9844 220.367L86.3262 231.107",
      "stroke": "#07B53B",
      "stroke-width": "2.89303",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M86.3281 220.367L96.9863 231.107",
      "stroke": "#07B53B",
      "stroke-width": "2.89303",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M246.022 31.6355C89.2966 23.9824 -9.01974 86.8752 50.6328 143.645C125.199 214.608 254.998 134.601 299.876 206.26C344.753 277.918 383.417 469.24 467.649 408.713C551.88 348.185 461.753 92.8581 544.604 65.7252",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-dasharray": "13.91 13.91"
    }
  }), _c('rect', {
    attrs: {
      "x": "161.055",
      "y": "227.555",
      "width": "105.946",
      "height": "63.3398",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "158",
      "y": "226",
      "width": "103.946",
      "height": "58.7858",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M163.184 306H232.652",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M246.27 306H288.495",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M209.5 297.766H278.968",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "194",
      "y": "250.965",
      "width": "7.44154",
      "height": "20.4037",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "206.277",
      "y": "242.465",
      "width": "7.44154",
      "height": "28.9053",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "218.559",
      "y": "238",
      "width": "7.44154",
      "height": "33.3686",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M331 71H194C194 53.0789 205.644 37.8366 221.857 32.2608C225.91 13.8157 242.536 0 262.434 0C282.302 0 298.913 13.7866 302.981 32.2026C319.282 37.7347 331 53.0207 331 71Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }