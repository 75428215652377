var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "638",
      "height": "594",
      "viewBox": "0 0 638 594",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M626 398H485C485 379.574 496.984 363.902 513.67 358.17C517.842 339.205 534.953 325 555.432 325C575.88 325 592.976 339.175 597.163 358.11C613.94 363.798 626 379.514 626 398Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M578.123 176L552.516 201.804L543.049 192.265L515 220.528",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M561.871 176H578.469V192.725",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M470 220.188L482.367 228.289",
      "stroke": "#07B53B",
      "stroke-width": "4.17428",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M472.164 230.466L480.201 218",
      "stroke": "#07B53B",
      "stroke-width": "4.17428",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M560.798 445.653C560.798 449.121 558.011 451.914 554.595 451.914C551.179 451.914 548.391 449.121 548.391 445.653C548.391 442.185 551.179 439.391 554.595 439.391C558.011 439.391 560.798 442.185 560.798 445.653Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2.78286"
    }
  }), _c('path', {
    attrs: {
      "d": "M369.984 378.367L359.326 389.107",
      "stroke": "#07B53B",
      "stroke-width": "2.89303",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M359.328 378.367L369.986 389.107",
      "stroke": "#07B53B",
      "stroke-width": "2.89303",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M452.946 443.168C592.501 371.433 646.199 267.808 566.153 248.47C466.096 224.297 393.689 358.485 318.995 318.865C244.301 279.246 115.156 132.889 72.473 227.423C29.7898 321.957 235.506 498.013 177.322 562.936",
      "stroke": "#ED6400",
      "stroke-width": "3",
      "stroke-linecap": "round",
      "stroke-dasharray": "13.91 13.91"
    }
  }), _c('rect', {
    attrs: {
      "x": "358.055",
      "y": "425.555",
      "width": "105.946",
      "height": "63.3398",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "355",
      "y": "424",
      "width": "103.946",
      "height": "58.7858",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "d": "M360.184 504H429.652",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M443.27 504H485.495",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M406.5 495.766H475.968",
      "stroke": "#07B53B",
      "stroke-width": "2",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "391",
      "y": "448.965",
      "width": "7.44154",
      "height": "20.4037",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "403.277",
      "y": "440.465",
      "width": "7.44154",
      "height": "28.9053",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "415.559",
      "y": "436",
      "width": "7.44154",
      "height": "33.3686",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M478 309H395C395 298.146 402.054 288.915 411.877 285.538C414.332 274.367 424.405 266 436.46 266C448.497 266 458.56 274.35 461.025 285.503C470.901 288.853 478 298.111 478 309Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }