<script>
import Tick from "../svg/why-nozax/Tick.vue";
export default {
  components: { Tick },
};
</script>
<template>
  <div class="d-flex flex-column align-items-center thiss">
    <!-- TITLE -->
    <div class="d-flex flex-column text-center w-75">
      <h2 class="">{{ $t("market.reasons.title") }}</h2>
      <h4 class="subtitle-bodyLarge text-grey-primary-main-500 w-fit mx-auto">
        {{ $t("market.reasons.subtitle") }}
      </h4>
    </div>

    <!-- Content -->
    <div class="container">
      <div class="row mt-40px">
        <div class="col-12 text-center col-xl-7 order-xl-2 text-xl-left ill">
          <img src="../images/WebIllustration.png" />
        </div>

        <div
          class="col-12 container reasons col-xl-5 order-xl-1 d-flex justify-content-start"
        >
          <div class="row reasons-box">
            <div
              class="d-flex justify-content-start align-items-center col-12 col-md-5 order-md-2 col-xl-12 order-xl-1"
            >
              <div class="tick-icon">
                <Tick />
              </div>
              <p class="text-grey-primary-main-500 roboto">
                {{ $t("market.reasons.item1") }}
              </p>
            </div>
            <div
              class="d-flex justify-content-start align-items-center col-12 col-md-5 order-md-4 col-xl-12 order-xl-4"
            >
              <div class="tick-icon">
                <Tick />
              </div>
              <p class="text-grey-primary-main-500 roboto">
                {{ $t("market.reasons.item4") }}
              </p>
            </div>
            <div
              class="d-flex justify-content-start align-items-center col-12 col-md-7 order-md-3 col-xl-12 order-xl-3"
            >
              <div class="tick-icon">
                <Tick />
              </div>
              <p class="text-grey-primary-main-500 roboto">
                {{ $t("market.reasons.item3") }}
              </p>
            </div>
            <div
              class="d-flex justify-content-start align-items-center col-12 col-md-7 order-md-1 col-xl-12 order-xl-2 h-fit"
            >
              <div class="tick-icon">
                <Tick />
              </div>
              <p class="text-grey-primary-main-500 roboto">
                {{ $t("market.reasons.item2") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.thiss {
  @media (max-width: 767px) {
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
    /* margin-top: 43px; */
  }
}
img {
  @media (max-width: 767px) {
    width: 100%;
  }
}
.reasons {
  @media (max-width: 767px) {
    /* background-color: green; */
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    /* background-color: red; */
  }
  @media (min-width: 1200px) {
    position: relative;
  }
}
p {
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  margin: 0 !important;

  font-size: 1.53em;
  @media (min-width: 768px) and (max-width: 1199px) {
    font-size: 21.2938px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    /* max-width: 210px !important; */
    /* or 22px */

    letter-spacing: -0.01em;
  }
}
h4 {
  /* or 22px */

  text-align: center;

  /* Grey/Primary-Main-500 */
}
h2 {
  @media (max-width: 767px) {
    margin-bottom: 16px !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 24px !important;
  }
}
.reasons-box {
  display: flex;
  @media (max-width: 767px) {
    gap: 16px;
    max-width: 276px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    width: 90%;
    row-gap: 24px;
    margin: 0 auto;
  }
  @media (min-width: 1200px) {
    margin-top: 80px;
    height: 240px;
  }
}
.tick-icon {
  margin-right: 8px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ill {
  @media (max-width: 767px) {
    margin-bottom: 25px !important;
  }
}
.mt-40px {
  margin-top: 102px;
}
</style>
