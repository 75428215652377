<template>
  <div class="position-relative">
    <div class="hero-twoPart position-relative overflow-hidden">
      <!-- DESKTOP 1 PIC -->
      <div class="d-none d-xl-flex justify-content-between">
        <DesktopLeft
          style="margin-top: -70px; margin-left: -300px; z-index: -1"
        />
        <DesktopRight style="margin-top: 122px; margin-right: -200px" />
      </div>

      <div
        class="justify-content-between d-none d-md-flex d-xl-none align-items-center"
      >
        <!-- Tablet 2 Pics -->
        <TabletLeftHero
          style="margin-top: -30px; margin-left: -260px; z-index: -1"
        />
        <TabletRightHero
          style="margin-top: -25px; margin-right: -100px; z-index: -1"
        />
      </div>

      <div class="hero-content" style="row-gap: 40px !important">
        <div>
          <h1 class="hero-header text-center light-theme-title">
            {{ $t("market.hero.header") }}&nbsp;<span class="text-primary">{{
              $t("market.hero.header-span")
            }}</span>
          </h1>
          <h4
            class="subtitle-bodyLarge hero-subtitle text-center text-grey-primary-main-500"
          >
            {{ $t("market.hero.sub-header") }}
          </h4>
        </div>
        <div class="d-flex justify-content-between d-md-none mobile-hero">
          <!-- MOBILE 2 Pics -->
          <MobileLeftHero id="left-mobile-hero" />
          <MobileRightHero id="right-mobile-hero" />
        </div>

        <b-button v-if="portal_url" @click="goToSignUp()" variant="primary">
          <p
            class="text-white-button button-large text-nowrap d-flex justify-content-center"
          >
            {{ $t("market.hero.start") }}
          </p></b-button
        >
      </div>
    </div>
    <div class="icons justify-content-around d-none d-md-flex">
      <div class="col d-flex flex-column align-items-center">
        <div
          class="single-icon p-3 mb-5 bg-white d-flex justify-content-center align-items-center"
        >
          <LeftIcon />
        </div>
        <p class="light-theme-title body-paragraph-bold">
          {{ $t("market.hero.trade") }}
        </p>
      </div>
      <div class="col d-flex flex-column align-items-center">
        <div
          class="single-icon p-3 mb-5 bg-white d-flex justify-content-center align-items-center"
        >
          <MiddleIcon />
        </div>
        <p class="light-theme-title body-paragraph-bold x">
          {{ $t("market.hero.instruments") }}
        </p>
      </div>
      <div class="col d-flex flex-column align-items-center">
        <div
          class="single-icon p-3 mb-5 bg-white d-flex justify-content-center align-items-center"
        >
          <RightIcon />
        </div>
        <p class="light-theme-title body-paragraph-bold">
          {{ $t("market.hero.spreads") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MobileRightHero from "../svg/hero/MobileRightHero.vue";
import MobileLeftHero from "../svg/hero/MobileLeftHero.vue";
import TabletLeftHero from "../svg/hero/TabletLeftHero.vue";
import TabletRightHero from "../svg/hero/TabletRightHero.vue";
import DesktopRight from "../svg/hero/DesktopRight.vue";
import DesktopLeft from "../svg/hero/DesktopLeft.vue";
import LeftIcon from "../svg/hero/LeftIcon.vue";
import RightIcon from "../svg/hero/RightIcon.vue";
import MiddleIcon from "../svg/hero/MiddleIcon.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    MobileRightHero,
    MobileLeftHero,
    TabletRightHero,
    TabletLeftHero,
    DesktopRight,
    DesktopLeft,
    LeftIcon,
    RightIcon,
    MiddleIcon,
  },
  computed: {
    ...mapGetters({
      portal_url: "portal_url",
    }),
  },
  methods: {
    goToSignUp() {
      if (this.portal_url)
        window.location.href = `${this.portal_url}/register}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icons {
  width: 75%;
  /* position: absolute; */
  @media (min-width: 1200px) {
    margin: -120px auto 136px auto;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin: 0 auto 105px auto;
  }
}
.single-icon {
  width: 96px;
  height: 96px;
  box-shadow: 0px 8px 16px rgba(218, 225, 233, 0.56);
  border-radius: 50px;
}

.mobile-hero {
  height: 290px;
  position: relative;
  width: 360px;
}
#left-mobile-hero {
  position: absolute;
  top: -70px;
  left: -250px;
}
#right-mobile-hero {
  position: absolute;
  top: 0;
  right: -140px;
}

p {
  text-align: center;
}

h1 {
  @media (max-width: 767px) {
    margin-top: 58px;
    margin-bottom: 32px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 75%;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 32px !important;
    margin-top: 50px;
  }
  @media (min-width: 1200px) {
    margin-top: 50px;
    margin-bottom: 32px !important;
  }
}
button {
  padding: 11px 24px;

  position: static;
  height: 56px;
  left: 192.5px;
  top: calc(50% - 56px / 2 + 85px);

  /* Primary Colors/Nozax Main */

  background: #07b53b;
  border-radius: 90px;
  @media (max-width: 767px) {
    height: 48px;
  }

  @media (min-width: 768px) {
    height: 56px;
  }
}
h4 {
  @media (max-width: 767px) {
    width: 80%;
    margin: auto;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
  }
  @media (min-width: 1200px) {
  }
}
.hero-content {
  @media (max-width: 767px) {
    margin-bottom: 150px;
  }
}
.body-paragraph-bold {
  max-width: 190px;
}
.x {
  max-width: 170px;
}
</style>
