var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "hero-twoPart position-relative overflow-hidden"
  }, [_c('div', {
    staticClass: "d-none d-xl-flex justify-content-between"
  }, [_c('DesktopLeft', {
    staticStyle: {
      "margin-top": "-70px",
      "margin-left": "-300px",
      "z-index": "-1"
    }
  }), _c('DesktopRight', {
    staticStyle: {
      "margin-top": "122px",
      "margin-right": "-200px"
    }
  })], 1), _c('div', {
    staticClass: "justify-content-between d-none d-md-flex d-xl-none align-items-center"
  }, [_c('TabletLeftHero', {
    staticStyle: {
      "margin-top": "-30px",
      "margin-left": "-260px",
      "z-index": "-1"
    }
  }), _c('TabletRightHero', {
    staticStyle: {
      "margin-top": "-25px",
      "margin-right": "-100px",
      "z-index": "-1"
    }
  })], 1), _c('div', {
    staticClass: "hero-content",
    staticStyle: {
      "row-gap": "40px !important"
    }
  }, [_c('div', [_c('h1', {
    staticClass: "hero-header text-center light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.hero.header")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.$t("market.hero.header-span")))])]), _c('h4', {
    staticClass: "subtitle-bodyLarge hero-subtitle text-center text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.hero.sub-header")) + " ")])]), _c('div', {
    staticClass: "d-flex justify-content-between d-md-none mobile-hero"
  }, [_c('MobileLeftHero', {
    attrs: {
      "id": "left-mobile-hero"
    }
  }), _c('MobileRightHero', {
    attrs: {
      "id": "right-mobile-hero"
    }
  })], 1), _vm.portal_url ? _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "text-white-button button-large text-nowrap d-flex justify-content-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.hero.start")) + " ")])]) : _vm._e()], 1)]), _c('div', {
    staticClass: "icons justify-content-around d-none d-md-flex"
  }, [_c('div', {
    staticClass: "col d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "single-icon p-3 mb-5 bg-white d-flex justify-content-center align-items-center"
  }, [_c('LeftIcon')], 1), _c('p', {
    staticClass: "light-theme-title body-paragraph-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.hero.trade")) + " ")])]), _c('div', {
    staticClass: "col d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "single-icon p-3 mb-5 bg-white d-flex justify-content-center align-items-center"
  }, [_c('MiddleIcon')], 1), _c('p', {
    staticClass: "light-theme-title body-paragraph-bold x"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.hero.instruments")) + " ")])]), _c('div', {
    staticClass: "col d-flex flex-column align-items-center"
  }, [_c('div', {
    staticClass: "single-icon p-3 mb-5 bg-white d-flex justify-content-center align-items-center"
  }, [_c('RightIcon')], 1), _c('p', {
    staticClass: "light-theme-title body-paragraph-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.hero.spreads")) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }