var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "464",
      "height": "432",
      "viewBox": "0 0 464 432",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M455.457 289.569H352.871C352.871 276.163 361.59 264.761 373.73 260.59C376.766 246.792 389.215 236.457 404.114 236.457C418.992 236.457 431.43 246.77 434.476 260.546C446.683 264.685 455.457 276.119 455.457 289.569Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M420.621 128.051L401.99 146.825L395.103 139.884L374.695 160.448",
      "stroke": "#ED6400",
      "stroke-width": "2.18268",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M408.797 128.051H420.873V140.219",
      "stroke": "#ED6400",
      "stroke-width": "2.18268",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M341.953 160.199L350.951 166.094",
      "stroke": "#07B53B",
      "stroke-width": "3.03704",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M343.527 167.679L349.375 158.609",
      "stroke": "#07B53B",
      "stroke-width": "3.03704",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M408.015 324.24C408.015 326.763 405.987 328.795 403.502 328.795C401.017 328.795 398.989 326.763 398.989 324.24C398.989 321.717 401.017 319.684 403.502 319.684C405.987 319.684 408.015 321.717 408.015 324.24Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "2.0247"
    }
  }), _c('path', {
    attrs: {
      "d": "M269.191 275.281L261.437 283.095",
      "stroke": "#07B53B",
      "stroke-width": "2.10485",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M261.434 275.281L269.188 283.095",
      "stroke": "#07B53B",
      "stroke-width": "2.10485",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M329.547 322.429C431.081 270.237 470.15 194.844 411.912 180.774C339.114 163.187 286.434 260.817 232.089 231.991C177.745 203.166 83.7844 96.6822 52.7298 165.461C21.6752 234.24 171.346 362.332 129.013 409.567",
      "stroke": "#ED6400",
      "stroke-width": "2.18268",
      "stroke-linecap": "round",
      "stroke-dasharray": "10.12 10.12"
    }
  }), _c('rect', {
    attrs: {
      "x": "260.5",
      "y": "309.617",
      "width": "77.0824",
      "height": "46.0835",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "258.278",
      "y": "308.485",
      "width": "75.6273",
      "height": "42.7702",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.45512"
    }
  }), _c('path', {
    attrs: {
      "d": "M262.055 366.691H312.597",
      "stroke": "#07B53B",
      "stroke-width": "1.45512",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M322.504 366.691H353.226",
      "stroke": "#07B53B",
      "stroke-width": "1.45512",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M295.754 360.699H346.296",
      "stroke": "#07B53B",
      "stroke-width": "1.45512",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "284.48",
      "y": "326.648",
      "width": "5.41417",
      "height": "14.845",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "293.414",
      "y": "320.465",
      "width": "5.41417",
      "height": "21.0304",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "302.348",
      "y": "317.215",
      "width": "5.41417",
      "height": "24.2777",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M347.774 224.816H287.387C287.387 216.92 292.519 210.203 299.666 207.746C301.452 199.619 308.781 193.531 317.551 193.531C326.309 193.531 333.631 199.606 335.424 207.721C342.609 210.158 347.774 216.894 347.774 224.816Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }