var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column align-items-center thiss"
  }, [_c('div', {
    staticClass: "d-flex flex-column text-center w-75"
  }, [_c('h2', {}, [_vm._v(_vm._s(_vm.$t("market.reasons.title")))]), _c('h4', {
    staticClass: "subtitle-bodyLarge text-grey-primary-main-500 w-fit mx-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.reasons.subtitle")) + " ")])]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row mt-40px"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-12 container reasons col-xl-5 order-xl-1 d-flex justify-content-start"
  }, [_c('div', {
    staticClass: "row reasons-box"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center col-12 col-md-5 order-md-2 col-xl-12 order-xl-1"
  }, [_c('div', {
    staticClass: "tick-icon"
  }, [_c('Tick')], 1), _c('p', {
    staticClass: "text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.reasons.item1")) + " ")])]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center col-12 col-md-5 order-md-4 col-xl-12 order-xl-4"
  }, [_c('div', {
    staticClass: "tick-icon"
  }, [_c('Tick')], 1), _c('p', {
    staticClass: "text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.reasons.item4")) + " ")])]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center col-12 col-md-7 order-md-3 col-xl-12 order-xl-3"
  }, [_c('div', {
    staticClass: "tick-icon"
  }, [_c('Tick')], 1), _c('p', {
    staticClass: "text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.reasons.item3")) + " ")])]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center col-12 col-md-7 order-md-1 col-xl-12 order-xl-2 h-fit"
  }, [_c('div', {
    staticClass: "tick-icon"
  }, [_c('Tick')], 1), _c('p', {
    staticClass: "text-grey-primary-main-500 roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("market.reasons.item2")) + " ")])])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12 text-center col-xl-7 order-xl-2 text-xl-left ill"
  }, [_c('img', {
    attrs: {
      "src": require("../images/WebIllustration.png")
    }
  })]);
}]

export { render, staticRenderFns }