<script>
import Hero from "./components/sections/Hero.vue";
import WhyNozax from "./components/sections/WhyNozax.vue";
import AreYouReady from "@/components/are-you-ready/AreYouReady.vue";
import MarketTrends from "@/components/market-trends/MarketTrends.vue";

export default {
  components: {
    Hero,
    MarketTrends,
    WhyNozax,
    AreYouReady,
  },
  props: ['showFull','marketType'],
  data() {
    return {
      marketTypeMeta: {
        Forex: {
        title  : "NOZAX: Speculate on Forex prices",
     description : "Forex enthusiast? We provide competitive spreads, commission-free accounts, and a user-friendly interface. Start trading today!"
        },
        Shares: {
          description: "Hundreds of shares from the world's leading stock exchanges are available to you on NOZAX. Sign up and start building your portfolio!",
      title: "NOZAX: CFD Stocks Trading "
        },
        Indices: {
          description: "Access global indices and speculate on their prices with NOZAX. Trade on-the-go today with cutting edge mobile and desktop tools. ",
      title: "NOZAX: CFD Indices Trading "
        },
        Crypto: {
          description: "Speculate on Crypto prices, and earn even in the bearish market. Sign up today, and start diversifying your portfolio with NOZAX. ",
      title: "NOZAX: CFD Crypto Trading "
        },
        Commodities: {
          description: "Speculate on the prices of oil, natural gas, gold, silver, wheat, corn and more on the global commodities & futures market.",
      title: "NOZAX: CFD Commodities Trading "
        },
      },

      title: '',
      description: ""
    }
  },
    metaInfo() {
      return {
        title: this.title || this.marketTypeMeta[this.$route.query.marketType].title ,
        meta: [
          { name: 'description', content: this.description || this.marketTypeMeta[this.$route.query.marketType].description},
        ]
      }
  },
    methods: {
      setMetaData(type) {
        this.title = this.marketTypeMeta[type].title;
        this.description = this.marketTypeMeta[type].description;
      }
  }
  
};
</script>
<template>
  <div>
    <Hero />
    <MarketTrends
      :marketTypeFromHome="marketType"
      :showFullFromHome="showFull"
      @setMetaData="setMetaData"
    />
    <WhyNozax />
    <AreYouReady class="dd" />
  </div>
</template>

<style lang="scss" scoped>
.dd {
  @media (max-width: 767px) {
    margin-top: 76px !important;
    margin-bottom: 59px !important;
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 100px !important;
    margin-bottom: 104px !important;
  }
  @media (min-width: 1200px) {
    margin-top: 66px !important;
    margin-bottom: 67px !important;
  }
}
</style>
