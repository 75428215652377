var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "47",
      "height": "47",
      "viewBox": "0 0 47 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_621_54995)"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.9167 0.5C14.9752 0.5 15.8333 1.35812 15.8333 2.41667V6.25C15.8333 7.30855 14.9752 8.16667 13.9167 8.16667C12.8581 8.16667 12 7.30855 12 6.25V2.41667C12 1.35812 12.8581 0.5 13.9167 0.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.9167 23.5C14.9752 23.5 15.8333 24.3581 15.8333 25.4167V36.9167C15.8333 37.9752 14.9752 38.8333 13.9167 38.8333C12.8581 38.8333 12 37.9752 12 36.9167V25.4167C12 24.3581 12.8581 23.5 13.9167 23.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M33.0833 8.16667C34.1419 8.16667 35 9.02479 35 10.0833V25.4167C35 26.4752 34.1419 27.3333 33.0833 27.3333C32.0248 27.3333 31.1667 26.4752 31.1667 25.4167V10.0833C31.1667 9.02479 32.0248 8.16667 33.0833 8.16667Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M33.0833 35C34.1419 35 35 35.8581 35 36.9167V44.5833C35 45.6419 34.1419 46.5 33.0833 46.5C32.0248 46.5 31.1667 45.6419 31.1667 44.5833V36.9167C31.1667 35.8581 32.0248 35 33.0833 35Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.16797 10.082C8.16797 6.90639 10.7423 4.33203 13.918 4.33203C17.0936 4.33203 19.668 6.90639 19.668 10.082V21.582C19.668 24.7577 17.0936 27.332 13.918 27.332C10.7423 27.332 8.16797 24.7577 8.16797 21.582V10.082ZM15.8346 10.082V21.582C15.8346 22.6406 14.9765 23.4987 13.918 23.4987C12.8594 23.4987 12.0013 22.6406 12.0013 21.582V10.082C12.0013 9.02349 12.8594 8.16536 13.918 8.16536C14.9765 8.16536 15.8346 9.02349 15.8346 10.082Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M27.3346 29.2487C27.3346 26.0731 29.909 23.4987 33.0846 23.4987C36.2603 23.4987 38.8346 26.0731 38.8346 29.2487V33.082C38.8346 36.2577 36.2603 38.832 33.0846 38.832C29.909 38.832 27.3346 36.2577 27.3346 33.082V29.2487ZM35.0013 29.2487V33.082C35.0013 34.1406 34.1432 34.9987 33.0846 34.9987C32.0261 34.9987 31.168 34.1406 31.168 33.082V29.2487C31.168 28.1902 32.0261 27.332 33.0846 27.332C34.1432 27.332 35.0013 28.1902 35.0013 29.2487Z",
      "fill": "#07B53B"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_621_54995"
    }
  }, [_c('rect', {
    attrs: {
      "width": "46",
      "height": "46",
      "fill": "white",
      "transform": "translate(0.5 0.5)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }