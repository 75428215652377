var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "315",
      "height": "244",
      "viewBox": "0 0 315 244",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M100.796 72.1053H52.918C52.918 65.8444 56.9872 60.5193 62.6533 58.5714C64.0699 52.1274 69.8802 47.3008 76.834 47.3008C83.7775 47.3008 89.5827 52.1172 91.0043 58.551C96.7013 60.4837 100.796 65.824 100.796 72.1053Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M37.4125 60.5703L22.6408 75.4552L17.18 69.9525L1 86.2564",
      "stroke": "#ED6400",
      "stroke-width": "1.73054",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M28.0391 60.5703H37.6133V70.2179",
      "stroke": "#ED6400",
      "stroke-width": "1.73054",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.3828 16.2617L18.5167 20.9352",
      "stroke": "#07B53B",
      "stroke-width": "2.40793",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.6328 22.1913L17.269 15",
      "stroke": "#07B53B",
      "stroke-width": "2.40793",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M169.901 81.7114C169.901 83.712 168.293 85.3233 166.322 85.3233C164.352 85.3233 162.744 83.712 162.744 81.7114C162.744 79.7108 164.352 78.0995 166.322 78.0995C168.293 78.0995 169.901 79.7108 169.901 81.7114Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.60529"
    }
  }), _c('path', {
    attrs: {
      "d": "M98.3694 5.56688C98.3694 7.56746 96.7616 9.17877 94.7911 9.17877C92.8206 9.17877 91.2128 7.56746 91.2128 5.56688C91.2128 3.5663 92.8206 1.95499 94.7911 1.95499C96.7616 1.95499 98.3694 3.5663 98.3694 5.56688Z",
      "fill": "white",
      "stroke": "#07B53B",
      "stroke-width": "1.60529"
    }
  }), _c('path', {
    attrs: {
      "d": "M55.793 127.121L49.6448 133.316",
      "stroke": "#07B53B",
      "stroke-width": "1.66884",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M49.6445 127.121L55.7927 133.316",
      "stroke": "#07B53B",
      "stroke-width": "1.66884",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M141.764 18.2494C51.3574 13.8347 -5.3562 50.1143 29.0543 82.8621C72.0674 123.797 146.942 77.645 172.83 118.981C198.717 160.317 221.02 270.681 269.609 235.766C318.198 200.851 266.208 53.5655 314.001 37.914",
      "stroke": "#ED6400",
      "stroke-width": "1.73054",
      "stroke-linecap": "round",
      "stroke-dasharray": "8.03 8.03"
    }
  }), _c('rect', {
    attrs: {
      "x": "92.7539",
      "y": "131.266",
      "width": "61.115",
      "height": "36.5375",
      "fill": "#07B53B"
    }
  }), _c('rect', {
    attrs: {
      "x": "90.9909",
      "y": "130.37",
      "width": "59.9613",
      "height": "33.9105",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.1537"
    }
  }), _c('path', {
    attrs: {
      "d": "M93.9805 176.516H134.053",
      "stroke": "#07B53B",
      "stroke-width": "1.1537",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M141.91 176.516H166.268",
      "stroke": "#07B53B",
      "stroke-width": "1.1537",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M120.699 171.766H160.772",
      "stroke": "#07B53B",
      "stroke-width": "1.1537",
      "stroke-linecap": "round"
    }
  }), _c('rect', {
    attrs: {
      "x": "111.754",
      "y": "144.77",
      "width": "4.29264",
      "height": "11.7699",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "118.836",
      "y": "139.863",
      "width": "4.29264",
      "height": "16.674",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "125.922",
      "y": "137.289",
      "width": "4.29264",
      "height": "19.2486",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M190.782 40.9562H111.754C111.754 30.6185 118.471 21.826 127.823 18.6096C130.161 7.96954 139.752 0 151.23 0C162.691 0 172.273 7.95275 174.619 18.576C184.023 21.7672 190.782 30.5849 190.782 40.9562Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }